.HeaderText {
  align-self: center;
  margin: 0;
  font-family: "Lato", sans-serif;
  color: #fff;
  font-size: 5vmax;
}

#AuthParent {
  width: 100vw;
  height: 100vh;
  background-color: #853b30;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.AuthInput {
  border-radius: 5px;
  font-family: "Lato", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 1.5vmax;
  padding: 20px 1.25vw;
  background-color: #fff;
  color: #853b30;
  width: 20vw;
}

.AuthInput::placeholder {
  color: #853b30c0;
  font-family: "Lato", sans-serif;
}

#AuthSubmitButton {
  border-radius: 7.5px;
  border-style: solid;
  border-width: 2.5px;
  border-color: #fff;
  padding: 10px;
  background-color: #fff;
  transition: background-color 0.5s, opacity 0.5s;
}

#AuthSubmitButtonText {
  font-family: "Lato", sans-serif;
  font-size: 2vmax;
  color: #853b30;
  transition: color 0.5s;
  width: 6vw;
  margin: 0;
}

#AuthSubmitButton:hover {
  background-color: #853b30;
}

#AuthSubmitButtonText:hover {
  color: #fff;
}

#ErrorToastContainer {
  position: absolute;
  top: 2.5vh;
  align-self: center;
  background-color: #fff;
  width: 75vw;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  transition: opacity 0.5s;
}

#ErrorToastText {
  color: #853b30;
  font-family: "Lato", sans-serif;
  font-size: 2vmax;
}
