.HomeScreenLabel {
  margin: 0;
  color: #fff;
  font-size: 2.5vmax;
  font-family: "Lato", sans-serif;
}

.NavBarText {
  margin: 0;
  color: #fff;
  font-size: 2vmax;
  font-family: "Lato";
  transition: color 0.5s;
}

.NavBarTextContainer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.CollapsedUnderline {
  width: 0%;
  height: 5px;
  transition: width 0.5s;
  background-color: #fff;
}

.UserApprovalContainer {
  width: 75%;
  padding: 20px;
  border-radius: 7.5px;
  background-color: #fff;
}

.ESContainer {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.UserApprovalNumber {
  margin: 0;
  color: #853b30;
  font-size: 2vmax;
  font-family: "Lato", sans-serif;
}

.AliasPrompt {
  margin: 0;
  color: #853b30;
  font-size: 2.5vmax;
  font-family: "Lato", sans-serif;
}

.ApprovalButtonContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.ViewUserTitle {
  color: #fff;
  font-size: 3vmax;
  font-family: "Lato", sans-serif;
}

/* 
  These are the same for now, but will be updated later to 
  make the UI more intuitive 
*/
.ApproveButton {
  width: 7.5vw;
  border-radius: 5px;
  background-color: #853b30;
}

.DenyButton {
  width: 7.5vw;
  border-radius: 5px;
  background-color: #853b30;
}
/*------------------------------------------------------*/

.ApprovalButtonText {
  margin: 7.5px;
  color: #fff;
  font-size: 1vmax;
  font-family: "Lato", sans-serif;
}

.AliasInput {
  width: 15vw;
  padding: 10px;
  font-size: 1.5vmax;
  color: #853b30;
  border-radius: 5px;
  border-width: 2.5px;
  border-style: solid;
  border-color: #853b30;
  font-family: "Lato", sans-serif;
}

.FinancialSnapshotContainer {
  padding: 20px;
  max-width: 75vw;
  min-width: 50vw;
  border-radius: 7.5px;
  background-color: #fff;
}

.FSAuthorName {
  margin: 0;
  color: #853b30;
  font-size: 2vmax;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.FSHeading {
  color: #853b30;
  font-size: 1.5vmax;
  font-family: "Lato", sans-serif;
}

.FSHeadingContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
}

.FSHeadingWrapper {
  padding: 15px;
  border-radius: 7.5px;
  border-width: 2.5px;
  border-style: solid;
  border-color: #853b30;
}

.FSSubHeading {
  color: #853b30;
  font-size: 1.5vmax;
  font-family: "Lato", sans-serif;
}

.PublishedPollSnapshotContainer {
  width: 50vw;
  padding: 25px;
  display: flex;
  align-items: center;
  border-radius: 7.5px;
  flex-direction: column;
  background-color: #fff;
}

.PPSInfoContainer {
  width: "100%";
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
}

.PPSTitle {
  margin: 0;
  color: #853b30;
  font-size: 2.5vmax;
  font-family: "Lato", sans-serif;
}

.PPSHeading {
  display: flex;
  margin: 0 50px;
  color: #853b30;
  font-size: 1.5vmax;
  align-items: center;
  flex-direction: row;
  font-family: "Lato", sans-serif;
}

.PPSHeadingContainer {
  margin-bottom: 25px;
}

.ViewPDFButton {
  margin: 0;
  user-select: none;
  color: #853b3050;
  font-size: 1.5vmax;
  transition: color 0.5s;
  font-family: "Lato", sans-serif;
}

.ViewPDFButton:hover {
  cursor: pointer;
  color: #853b30ff;
}

.VUPanel {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.VUPanelLabel {
  margin: 0;
  color: #fff;
  font-size: 5vmin;
  font-family: "Lato", sans-serif;
}

.AUPContainer {
  width: 80%;
  padding: 2.5vmin;
  border-radius: 5px;
  background-color: #fff;
}

.AUPDescriptor {
  color: #853b30a0;
  font-size: 2.5vmin;
  font-family: "Lato", sans-serif;
}

.AUPDescriptorContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.AUPItem {
  color: #853b30;
  font-size: 2.5vmin;
  font-family: "Lato", sans-serif;
}

.AUPAliasContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AUPAliasInput {
  padding: 1vmin;
  color: #853b30;
  font-size: 2.5vmin;
  border-radius: 5px;
  border-width: 1.5px;
  border-style: solid;
  border-color: #853b30;
  font-family: "Lato", sans-serif;
}

.AUPButton {
  height: 100%;
  font-size: 2vmin;
  border-radius: 5px;
  border-style: solid;
  border-color: #853b30;
  background-color: #fff;
  transition: background-color 0.25s;
}

#AnimatedUnderline {
  width: 100%;
}

#EmployeeNavBar {
  width: 100vw;
  display: flex;
  border-width: 0;
  border-style: solid;
  align-items: center;
  border-color: #fff;
  border-bottom-width: 0.25vw;
  justify-content: space-evenly;
}

#NoPollsToView {
  color: #fff;
  width: 50vmax;
  font-size: 2.5vmax;
  text-align: center;
  font-family: "Lato", sans-serif;
}

#NothingToApprove {
  color: #fff;
  width: 50vmax;
  font-size: 2.5vmax;
  text-align: center;
  font-family: "Lato", sans-serif;
}

#LogOutButton {
  padding: 10px;
  border-radius: 7.5px;
  background-color: #fff;
}

#LogOutButtonText {
  margin: 0;
  color: #853b30;
  font-size: 1vmax;
  font-size: 2vmax;
  font-family: "Lato", sans-serif;
}

#VUPanelContainer {
  width: 100vw;
  display: flex;
  flex-direction: row;
}
