.HomeScreenLabel {
  margin: 0;
  color: #fff;
  font-size: 2.5vmax;
  font-family: "Lato", sans-serif;
}

.HorizontalCenterView {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#HomeScreenContainer {
  width: 100vw;
  overflow: hidden;
  min-height: 100vh;
  text-align: center;
  background-color: #853b30;
}

#HomeScreenTyping {
  color: #fff;
  white-space: pre;
  font-size: 5vmax;
  font-family: "Lato", sans-serif;
}

#HomeScreenPollContainer {
  margin: 0;
}

/* 
#HomeScreenPollContainer {
  margin: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 0 2.5vw;
  overflow-y: scroll;
  align-items: center;
  flex-direction: column;
} */
