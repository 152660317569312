.AdminLoginInput {
  border-radius: 5px;
  font-family: "Lato", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 1.5vmax;
  padding: 20px 1.25vw;
  background-color: #fff;
  color: #853b30;
  width: 20vw;
}

.AdminLoginInput::placeholder {
  color: #853b30c0;
  font-family: "Lato", sans-serif;
}

.typed-cursor {
  color: #fff;
  font-size: 5vmax;
}

#AdminLoginContainer {
  width: 100vw;
  height: 100vh;
  background-color: #853b30;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#AdminGrowingSpacer {
  width: 100vw;
  height: 0px;
  animation-duration: 2s;
  animation-name: spacerGrow;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

#AdminLoginScreenTyping {
  white-space: pre;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 5vmax;
}

#AdminLoginScreenButton {
  width: 10vw;
  padding: 10px;
  border-radius: 10px;
  border-style: solid;
  border-color: #fff;
  border-width: 2.5px;
  background-color: #853b30;
  transition: background-color 0.5s;
}

#AdminLoginButtonText {
  font-family: "Lato", sans-serif;
  font-size: 2vmax;
  margin: 0;
  color: #fff;
  transition: color 0.5s;
}

#AdminLoginScreenButton:hover {
  background-color: #fff;
}

#AdminLoginButtonText:hover {
  color: #853b30;
}

@keyframes spacerGrow {
  from {
    height: 1vh;
  }

  to {
    height: 100px;
  }
}
