.PBTitle {
  margin: 0;
  color: #fff;
  font-weight: bold;
  font-size: 3vmax;
  font-family: "Lato", sans-serif;
}

.PBSpaceFiller {
  flex: 1;
  width: 100vw;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  /* justify-content: center; */
}

.PBHeading {
  margin: 0;
  color: #fff;
  font-size: 3vmax;
  margin-left: 25px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.PBCenterView {
  width: 100vw;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.PBSubheading {
  margin: 0;
  color: #fff;
  /* margin-top: 10px; */
  font-size: 2vmax;
  font-family: "Lato", sans-serif;
}

.PBContainer {
  /* padding: 10px; */
  display: flex;
  margin: 0 25px;
  width: min-content;
  align-self: center;
  align-items: center;
  border-radius: 7.5px;
  flex-direction: column;
  background-color: #fff;
}

.PBContainerMapModal {
  opacity: 0;
  width: 25vw;
  z-index: -1;
  display: flex;
  height: 65vh;
  position: absolute;
  align-items: center;
  border-radius: 7.5px;
  justify-content: center;
  background-color: #00000050;
  transition: z-index 0.5s, opacity 0.5s;
}

.CountyLikeSnapshot {
  padding: 10px;
  border-radius: 7.5px;
  background-color: #fff;
}

.CountyLikeSnapshotText {
  margin: 0;
  color: #853b30;
  font-size: 1vmax;
  font-family: "Lato", sans-serif;
}

#PBContainerMapModalVisible {
  z-index: 1;
  opacity: 1;
}

#PollBreakdownMainContainer {
  /* page-break-inside: avoid; */
  width: 100vw;
  min-height: 100vh;
  background-color: #853b30;
}

#savePDF:hover {
  cursor: pointer;
}

/* Button.css */

.export-button {
  background-color: #d08b81;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px; /* Add rounded corners */
  margin: 0 auto; /* Center the button horizontally */
  display: block; /* Make the button a block element */
  width: 120px; /* Adjust the width as needed */
}

.export-button:hover {
  background-color: #d26554;
}

.export-button:active {
  background-color: #cc5643;
}

.select-box {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 10px 15px;
  font-size: 1rem;
  transition: border-color 0.3s;
  outline: none;
  color: #555;
  cursor: pointer;
  width: auto; /* adjust width as required */
  margin: 0 40px; /* Add some margin on sides for spacing if needed */
}

.select-box:hover {
  border-color: #aaa;
}

.select-box:focus {
  border-color: #888;
}